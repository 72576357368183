<template>
    <div class="tsetting">
        <div class="left">
            <el-form ref="form" :model="form" label-width="160px" :rules="rules">
                <el-form-item label="编辑购物小票模板：">
                    <div>日期、单号（小票必须展示的信息）</div>
                </el-form-item>
                <el-form-item label="选填项：">
                    <el-checkbox-group v-model="form.checkList">
                        <el-checkbox v-for="item in checkList" :key="item.val" :label="item.val"
                            @change="handleInput($event ? 1 : 0, item.val)">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="店铺名称：">
                    <el-radio-group v-model="form.storename_radio" @change="handleInput($event, 'type')">
                        <el-radio :label="0">默认店铺名称</el-radio>
                        <el-radio :label="1">自定义店铺名称</el-radio>
                    </el-radio-group>
                    <div v-if="form.storename_radio == 1">
                        <el-input v-model="form.store_name" class="inputClass"
                            @input="handleInput($event, 'store_name')"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="脱敏信息：">
                    <el-checkbox-group v-model="form.checkList2">
                        <el-checkbox v-for="item in checkList2" :key="item.val" :label="item.val"
                            @change="handleInput($event ? 1 : 0, item.val)">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <div class="ticket">
                <div class="store_name" v-if="form.checkList.indexOf('is_print_store_name') != -1">
                    {{ form.storename_radio == 1 ? form.store_name : form.name }}</div>
                <div class="row row1">日期：2021-04-12 12:20:21</div>
                <div class="row">单号：#################</div>
                <div class="row2">
                    <span class="item1">商品名称</span>
                    <span class="item2">单价</span>
                    <span class="item2">数量</span>
                    <span class="item2">金额</span>
                </div>
                <div class="row2 row3">
                    <span class="item1">#####/规格：#</span>
                    <span class="item2">#</span>
                    <span class="item2">#</span>
                    <span class="item2">#</span>
                </div>
                <div class="row4">
                    <div class="item3">订单总价：#######</div>
                    <div class="item3">优惠金额：#######</div>
                    <div class="item3">会员折扣金额：#######</div>
                    <div class="item3">会员余额抵扣金额：#######</div>
                    <div class="item3">实付金额：#######</div>
                    <div class="item3">支付方式：#######</div>
                </div>
                <div class="row5">
                    <div class="item3" v-if="form.checkList.indexOf('is_print_member_card') != -1">
                        会员卡号：{{ form.checkList2.indexOf('is_sensitive_member_card') != -1 ? '2021*****4280' :
                            '2021123454280' }}
                    </div>
                    <div class="item3" v-if="form.checkList.indexOf('is_print_user_name') != -1">
                        会员姓名：{{ form.checkList2.indexOf('is_sensitive_user_name') != -1 ? '张*明' : '张小明' }}</div>
                    <div class="item3" v-if="form.checkList.indexOf('is_print_mobile') != -1">
                        会员手机：{{ form.checkList2.indexOf('is_sensitive_mobile') != -1 ? '135****1234' : '13512341234' }}
                    </div>
                    <div class="item3" v-if="form.checkList.indexOf('is_meal_code') != -1">
                        取餐码：#####
                    </div>
                    <div class="item3" v-if="form.checkList.indexOf('is_wifi_name') != -1">
                        房间号：#####
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['printerInfo'],
    data () {
        return {
            form: {
                checkList: [],
                storename_radio: 1,
                store_name: "",
                checkList2: [],
                name: ""
            },
            checkList: [{
                name: '店铺名称',
                val: 'is_print_store_name'
            }, {
                name: '会员卡号',
                val: 'is_print_member_card'
            }, {
                name: '会员姓名',
                val: 'is_print_user_name'
            }, {
                name: '会员手机',
                val: 'is_print_mobile'
            }, {
                name: '取餐码',
                val: 'is_meal_code'
            }, {
                name: '房间号',
                val: 'is_wifi_name'
            }],
            checkList2: [{
                name: '会员卡号',
                val: 'is_sensitive_member_card'
            }, {
                name: '会员姓名',
                val: 'is_sensitive_user_name'
            }, {
                name: '会员手机',
                val: 'is_sensitive_mobile'
            }]
        }
    },
    watch: {
        printerInfo (val) {
            let checkList = []
            this.checkList.forEach(item => {
                if (val[item.val]) {
                    checkList.push(item.val)
                }
            })
            let checkList2 = []
            this.checkList2.forEach(item => {
                if (val[item.val]) {
                    checkList2.push(item.val)
                }
            })
            this.form = {
                checkList: checkList,
                storename_radio: val.type,
                store_name: val.store_name,
                checkList2: checkList2,
                name: val.name
            }
        }
    },
    methods: {
        handleInput (val, attr) {
            this.$emit('handle-input', {
                attr, val
            })
        }
    },
    created () {
        if (this.printerInfo) {
            let checkList = []
            this.checkList.forEach(item => {
                if (this.printerInfo[item.val]) {
                    checkList.push(item.val)
                }
            })
            let checkList2 = []
            this.checkList2.forEach(item => {
                if (this.printerInfo[item.val]) {
                    checkList2.push(item.val)
                }
            })
            this.form = {
                checkList: checkList,
                storename_radio: this.printerInfo.type,
                store_name: this.printerInfo.store_name,
                checkList2: checkList2,
                name: this.printerInfo.name
            }
        }
    }
}
</script>

<style scoped lang="less">
.tsetting {
    display: flex;
    justify-content: space-between;
}

.left,
.right {
    flex: 1;
}

.inputClass {
    width: 460px;
}

.ticket {
    margin: 0 auto;
    width: 260px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    padding: 42px 15px 26px;
    font-size: 13px;
    color: #555;
    overflow: hidden;

    .store_name {
        text-align: center;
        font-size: 24px;
    }

    .row {
        margin-bottom: 6px;
    }

    .row1 {
        margin-top: 14px;
    }

    .row2 {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #ddd;
        padding-bottom: 10px;

        .item1 {
            flex: 2;
        }

        .item2 {
            flex: 1;
            flex-shrink: 0;
        }
    }

    .row3 {
        padding-top: 10px;
    }

    .row4 {
        padding: 10px 0;
        border-bottom: 1px dashed #ddd;
    }

    .row5 {
        padding-top: 10px;
    }

    .item3 {
        line-height: 18px;
    }
}
</style>
